import { css } from 'react-emotion'
import theme, { media } from '../styles/theme'

export default css`
  display: grid;
  grid-gap: 20px 0;
  margin-top: 40px;

  ${media.greaterThan('xSmall')`
    margin-top: 0;
  `};

  ${media.greaterThan('small')`
    grid-gap: 0 20px;
    grid-template-columns: 1fr 1fr;
  `};

  &:after {
    background-color: ${theme.colors.introRule};
    width: 100%;
  }
`
