import { rem } from 'polished'
import styled from 'react-emotion'

export default styled.span`
  display: block;
  height: ${rem('24px')};
  color: #4a4a4a;
  font-size: ${rem('14px')};
  line-height: 1;
`
