import styled from 'react-emotion'
import { rem } from 'polished'
import { media } from '../styles/theme'

export default styled.label`
  display: block;

  input,
  textarea {
    padding: 10px 15px;
    font-size: ${rem('16px')};
    width: 100%;
    border: 1px solid #979797;
    border-radius: 4px;
    background-color: #fff;
  }

  textarea {
    height: 250px;
  }

  &.full {
    ${media.greaterThan('small')`
    grid-column: 1 / 3;
  `};
  }
`
