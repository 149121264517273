import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'react-emotion'
import Helmet from 'react-helmet'
import { rem } from 'polished'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import HeroContent from '../components/HeroContent'
import {
  HeroHeadingPrimary,
  HeroHeadingSecondary,
} from '../components/HeroHeadings'
import LayoutContainer from '../components/LayoutContainer'
import General from '../components/General'
import SectionHeading from '../components/SectionHeading'
import Section, { sectionStyles } from '../components/Section'
import FloatingRuleStyle from '../styles/FloatingRuleStyle'
import FormStyles from '../styles/FormStyles'
import Button from '../components/Button'
import Honeypot from '../components/Honeypot'
import theme, { media } from '../styles/theme'
import Field from '../components/Field'
import Label from '../components/Label'
import Submit from '../components/Submit'

const HONEYPOT_NAME = 'ignore-me'
const email = ''

const GeneralHeading = HeroHeadingPrimary.withComponent('h1')

const SidebarLayoutContainer = styled(LayoutContainer)`
  ${sectionStyles};

  ${media.greaterThan('xSmall')`
    display: grid;
    grid-gap: 0 10px;
    grid-template-columns: 210px auto;
  `};

  ${media.greaterThan('small')`
    grid-gap: 0 100px;
  `};
`

const SidebarSectionHeading = styled(SectionHeading)`
  margin-top: 0;
  margin-bottom: 0;
`

export default ({ data, pageContext }) => {
  const general = data.general.data
  const { site } = data

  const simpleForm = (
    <form
      name="contact"
      method="POST"
      className={css`
        ${FloatingRuleStyle};
        ${FormStyles};
      `}
      data-netlify="true"
      data-netlify-honeypot={HONEYPOT_NAME}>
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="subject" value="Website Contact" />
      <Honeypot />
      <Field>
        <Label>First Name</Label>
        <input required type="name" name="First Name" maxLength="30" />
      </Field>
      <Field>
        <Label>Last Name</Label>
        <input required type="name" name="Last Name" maxLength="30" />
      </Field>
      <Field>
        <Label>Email</Label>
        <input
          required
          type="email"
          name="Email"
          maxLength="200"
          title="Please provide only a valid email address"
          pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
        />
      </Field>
      <Field>
        <Label>Phone</Label>
        <input required type="tel" name="Phone" />
      </Field>
      <Field className="full">
        <Label>Message</Label>
        <textarea required rows="4" type="tel" name="Message" />
      </Field>
      <div
        className={css`
          ${media.greaterThan('small')`
            grid-column: 1 / 3;
          `};
        `}>
        <Submit type="submit" label="Submit" />
      </div>
    </form>
  )

  return (
    <Layout>
      <Helmet>
        <title>Contact - {site.siteMetadata.title}</title>
      </Helmet>
      <Hero
        {...pageContext.heroProps}
        src={general.heroImage.url}
        alt={general.title}>
        <HeroContent>
          <GeneralHeading>{general.title}</GeneralHeading>
        </HeroContent>
      </Hero>
      <SidebarLayoutContainer>
        <General>
          <SidebarSectionHeading>
            Drop Us
            <br />a Line
          </SidebarSectionHeading>
          <div dangerouslySetInnerHTML={{ __html: general.body.html }} />
        </General>
        {simpleForm}
      </SidebarLayoutContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    general: prismicContact {
      data {
        title
        heroImage: hero_image {
          url
          dimensions {
            width
            height
          }
        }
        body {
          html
        }
      }
    }
  }
`
