// @form

import React from 'react'
import styled from 'react-emotion'

const HONEYPOT_NAME = 'ignore-me'

const HiddenField = styled.div`
  display: none;
`

type HoneypotProps = {
  honeypotName: String,
}

export default ({ honeypotName }: HoneypotProps) => (
  <HiddenField>
    <label htmlFor={honeypotName || HONEYPOT_NAME}>
      Don’t fill in this field:{' '}
      <input
        id={honeypotName || HONEYPOT_NAME}
        name={honeypotName || HONEYPOT_NAME}
      />
    </label>
  </HiddenField>
)
